<template>
    <div class="page_content">
        <div class="page_edit_content page_detail">
            <a-divider orientation="left">投放详情</a-divider>
            <a-descriptions :column="2">
                <a-descriptions-item label="申请客户">
                    {{ detail.customer_name }}
                </a-descriptions-item>
                <a-descriptions-item label="投放类型">
                    {{ detail.type_name }}
                </a-descriptions-item>
                <a-descriptions-item label="事由">
                    {{ detail.desc }}
                </a-descriptions-item>
                <a-descriptions-item label="关联合约">
                    <a @click="to_contract">{{ detail.contract_name }}</a>
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="图片">
                    <LookImages v-if="detail.leave_img" :list="detail.leave_img.split(',')"></LookImages>
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="物料">
                    <a-table
                        class="mt10"
                        :columns="columns"
                        :pagination="false"
                        :dataSource="detail.goods_data"
                    >
                    </a-table>
                </a-descriptions-item>
				<a-descriptions-item v-if="detail.cash" :span="2" label="现金">
                    {{detail.cash}}元
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="投放周">
                    {{ detail.launch_week }}
                </a-descriptions-item>
                <a-descriptions-item :span="2" label="审批进度">
                </a-descriptions-item>
            </a-descriptions>

            <div class="">
                <a-timeline class="time_line">
                    <a-timeline-item
                        class="li"
                        :class="[index == approval.length - 1 ? 'last_li' : '']"
                        v-for="(item, index) in approval"
                    >
                        <div class="title">
                            <span v-if="item.type == 'director'">指定上级</span>
                            <span v-else-if="item.type == 'appoint'"
                                >指定成员</span
                            >
                            <span v-else-if="item.type == 'optional'"
                                >发起人自选</span
                            >
                            <span v-else-if="item.type == 'role'"
                                >指定角色</span
                            >
                            <span v-else-if="item.type == 'user'"
                                >审批人
                                <span>{{ item.user[0].username }}</span>
                                <span> {{ item.user[0].approve_time }}</span>
                            </span>
                            <template v-for="manner in approval_manner_list">
                                <span
                                    class="tit_info"
                                    v-if="manner.value == item.mode"
                                    >({{ manner.label }})</span
                                >
                            </template>
                        </div>
                        <div v-if="item.type == 'user'">
                            <div v-if="item.user[0].sign">
                                <!-- <div>签名</div> -->
								<LookImages  :list="[item.user[0].sign]"></LookImages>
                            </div>
                        </div>

                        <div v-else class="avatar_ul">
                            <div v-for="vo in item.user" class="avatar">
                                <Avatar
                                    :show_name="true"
                                    :value="vo.username"
                                    :show_enter="vo.status > 0"
                                ></Avatar>
                            </div>
                        </div>
                        <div
                            v-if="item.type == 'user'"
                            style="padding-top: 10px"
                        >
                            {{ item.user[0].desc }}
                        </div>
                    </a-timeline-item>
                </a-timeline>

                <div>抄送人</div>
                <div class="time_line">
                    <div class="avatar_ul">
                        <div v-for="vo in detail.flow.cc_user" class="avatar">
                            <Avatar
                                :show_name="true"
                                :value="vo.username"
                                :show_enter="vo.status > 0"
                            ></Avatar>
                        </div>
                    </div>
                </div>
            </div>

            <a-divider orientation="left">发放列表</a-divider>

            <div class="li_item" v-for="(item, index) in grant_detail" style="padding: 20px;background-color: #fafafa;margin-bottom: 10px;">
                <div class="title" :style="item.approval_id == grant_id?'color:#1890ff;font-weight:700':''" style="padding-bottom: 16px;">{{ index + 1 }}、{{ item.week }}</div>
                <a-descriptions :column="2">
                    <a-descriptions-item label="发放时间">
                        {{ item.week }}
                    </a-descriptions-item>
                    <a-descriptions-item label="发放审批状态">
                        {{ item.approval_status_name }}
                    </a-descriptions-item>
                    <a-descriptions-item label="配送人">
                        {{ item.give_name }}
                    </a-descriptions-item>
                    <a-descriptions-item label="客户确认状态">
                        {{ item.write_status_name }}
                        <!-- <LookImages :list=""></LookImages> -->
                    </a-descriptions-item>
					<!-- status:2为异常核销，需要显示异常原因 -->
					<a-descriptions-item label="异常原因" v-if="item.write_status == 2" :span="item.cash?1:2">
						{{ item.reason }}
					</a-descriptions-item>
					<a-descriptions-item label="现金" v-if="item.cash" :span="item.write_status == 2?1:2">
						{{ item.cash }}元
					</a-descriptions-item>
					
					<a-descriptions-item :span="2" label="物料">
					    <a-table
					        class="mt10"
					        :columns="grant_columns"
					        :pagination="false"
					        :dataSource="item.goods_data"
					    >
					    </a-table>
					</a-descriptions-item>
                </a-descriptions>
            </div>

            <a-divider orientation="left">监管详情</a-divider>

            <div class="li_item" v-for="item in supervision_detail">
                <div class="title" style="padding-bottom: 15px">
                    {{ item.title }}
                </div>
                <a-descriptions :column="2">
                    <a-descriptions-item label="监管类型">
                        {{ item.type }}
                    </a-descriptions-item>
                    <a-descriptions-item label="监管说明">
                        {{ item.reason }}
                    </a-descriptions-item>
                    <a-descriptions-item :span="2" label="监管处理">
                        {{ item.status_name }}
                    </a-descriptions-item>
                    <a-descriptions-item :span="2" label="监管图片">
                        <!-- {{ item.type }} -->
                    </a-descriptions-item>
                    <a-descriptions-item :span="2" label="物料">
                        <a-table
                            class="mt10"
                            :columns="supervision_columns"
                            :pagination="false"
                            :dataSource="item.goods_data"
                        >
                        </a-table>
                    </a-descriptions-item>
                </a-descriptions>
            </div>

            <a-divider orientation="left">结算详情</a-divider>
            <div class="li_item">
                <a-table
                    class="mt10"
                    :columns="settle_columns"
                    :pagination="false"
                    :dataSource="settle_detail"
                >
                </a-table>
            </div>


			<div class="box_form box_form_btns">
                <a-button
                    type="defalut"
                    @click="$router.back()"
                    >返回</a-button
                >
               
            </div>
        </div>
    </div>
</template>

<script>
import LookImages from "@/components/LookImages";
import { constLaunchDetails } from '@/api/cost'
import Avatar from "@/components/small/Avatar";
const columns = [
    {
        title: "品项",
        dataIndex: "goods_title",
    },
    {
        title: "数量",
        dataIndex: "num",
    },
    {
        title: "投放位置",
        dataIndex: "name",
    },
    {
        title: "投放月份",
        dataIndex: "put_month",
    },
];

const grant_columns = [
	{
		title: "品项",
		dataIndex: "goods_title",
	},
	{
		title: "数量",
		dataIndex: "num",
	},
	{
		title: "实际数量",
		dataIndex: "actual_num",
	},
	{
	    title: "投放位置",
	    dataIndex: "name",
	},
];

const supervision_columns = [
    {
        title: "品项",
        dataIndex: "goods_title",
    },
    {
        title: "原发放数量",
        dataIndex: "actual_num",
    },
    {
        title: "投放位置",
        dataIndex: "name",
    },
    {
        title: "投放月份",
        dataIndex: "week",
    },
    {
        title: "监管操作",
        dataIndex: "num",
    },
];
const settle_columns = [
    {
        title: "发放名称",
        dataIndex: "approval_type_name",
    },
    {
        title: "结算单位名称",
        dataIndex: "name",
    },
    {
        title: "结算时间",
        dataIndex: "account_time",
    },
    {
        title: "结算状态",
        dataIndex: "status",
    },
];
export default {
    components: {
        LookImages,
        Avatar
    },
    data() {
        return {
            columns,
            approval: [],
            detail: {
                goods_data: [],
                flow: {
                    cc_user: []
                }
            },
            grant_detail: [],
            supervision_detail: [],
            supervision_columns,
            settle_detail: [],
            settle_columns,
			grant_columns,
			now_grant: {},
            approval_manner_list: [
                { label: '依次审批', value: 'order' },
                { label: '会签审批', value: 'jointly' },
                { label: '或签审批', value: 'or' },
            ],
			grant_id: 0
        }
    },
    created() {
		this.grant_id = this.$route.query.grant_id
        this.get_info()
    },
    methods: {
        to_contract() {
            this.$router.push('contract_detail?id=' + this.detail.contract_id)
        },

        get_info() {
            constLaunchDetails({
                data: {
                    id: this.$route.query.id,
					grant_id: this.grant_id
                }
            }).then(res => {
                this.detail = res.data.launch_detail
                let approval_user = [];
                let flow = res.data.launch_detail.flow.flow
                if (flow.approval_user) {
                    approval_user = flow.approval_user.map((item) => {
                        return {
                            type: 'user',
                            user: [item]
                        }
                    })
                }

                this.approval = [...flow.approval, ...approval_user]

				this.now_grant = res.data.now_grant
                this.grant_detail = res.data.grant_detail
                this.supervision_detail = res.data.supervision_detail
                this.settle_detail = res.data.settle_detail
            })
        }
    }
}
</script>

<style lang="less" scoped>
.time_line {
    max-width: 600px;
}
.avatar_ul {
    display: flex;
    flex-wrap: wrap;
    .avatar {
        margin: 16px 10px 0;
        width: 80px;
    }
}
/deep/ .ant-table-tbody{
	background-color: #fff !important;
}
/deep/ .ant-table-thead tr th{
	background-color: #eee !important;
}
</style>